<template>
  <v-container>
    <template>
      <v-data-table :headers="headers" :items="groups" sort-by="id" :loading="loading" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Group Manager</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> New Group </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-form ref="AddGroupForm" @submit.prevent="save">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Group Name"
                            :rules="[rules.required]"
                            :error-messages="errorFor('name')"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
                    <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize"> Reset </v-btn>
        </template>
      </v-data-table>
    </template>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import errors from '@/shared/mixins/errors'

export default {
  name: 'GroupManagerIndex',
  mixins: [errors],
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'Group ID', value: 'id' },
      { text: 'Group Name', value: 'name', width: '70%' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    editedIndex: -1,
    loading: true,
    editedItem: {
      name: ''
    },
    defaultItem: {
      name: ''
    }
  }),

  computed: {
    ...mapState({
      user: (state) => state.auth.userData,
      groups: (state) => state.groups.groups
    }),
    rules() {
      return {
        required: (value) => !!value || 'Required'
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Group' : 'Edit Group'
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    }
  },

  created() {
    this.initialize()
  },

  methods: {
    ...mapActions('groups', ['fetchGroups', 'addGroup', 'delGroup', 'patchGroup']),
    async initialize() {
      this.loading = true
      if (this.groups.length === 0) {
        await this.fetchGroups()
      }
      this.loading = false
    },

    editItem(item) {
      this.editedIndex = this.groups.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.groups.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.groups.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      this.$store.commit('setErrors', {})
      if (this.$refs.AddGroupForm.validate()) {
        this.loading = true
        const data = new FormData()
        data.append('name', String(this.editedItem.name))
        this.editedItem.id ? data.append('id', this.editedItem.id) : null

        if (this.editedIndex > -1) {
          try {
            await this.patchGroup(data)
            const isErrors = Object.keys(this.errors).length > 0
            if (!isErrors) {
              this.close()
              this.$refs.AddGroupForm.resetValidation()
            }
            this.loading = false
          } catch (error) {
            this.loading = false
          }
        } else {
          try {
            await this.addGroup(data)
            const isErrors = Object.keys(this.errors).length > 0
            if (!isErrors) {
              this.close()
              this.$refs.AddGroupForm.resetValidation()
            }
            this.loading = false
          } catch (error) {
            this.loading = false
          }
        }
      }
    }
  }
}
</script>

<style></style>
